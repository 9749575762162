import type {AxiosError} from 'axios';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';

import {api, setApiToken} from '@eksab/api';
import * as cache from '@eksab/cache';
import {useFetchMe, useSetAccessToken} from '@eksab/hooks';
import {config} from '@eksab/config';
import {sendTwitterCompeteteRegistrationEvent} from '@eksab/lib/sendTwitterEvents';

import {useAuthStore} from '../state';
import type {PartnerCode, RegistrationResponse} from '../types';

type PartnerReferral =
  | {
      [K in PartnerCode]: {
        [P in K as `${P}-code`]?: never;
      };
    }[PartnerCode]
  | {
      [K in PartnerCode]: {
        [P in K as `${P}-code`]: string;
      };
    }[PartnerCode];

type RegistrationParams = {
  name: string;
  phone_number: string;
  email?: string;
  otp: string;
  password: string;
  recaptcha: string;
  referrer?: string;
} & PartnerReferral;

export function useRegisterMutation(
  options?: UseMutationOptions<RegistrationResponse, AxiosError, RegistrationParams>,
) {
  const setLoggedIn = useAuthStore((state) => state.setLoggedIn);
  const fetchMe = useFetchMe();
  const setAccessToken = useSetAccessToken();

  return useMutation<RegistrationResponse, AxiosError, RegistrationParams>(
    (params) =>
      api.post<RegistrationResponse>('/api/register', params, {baseURL: config.baseURL}).then((res) => res.data),
    {
      ...options,
      onSuccess: async (...params) => {
        try {
          setLoggedIn(true);
          fbq?.('track', 'CompleteRegistration');

          setApiToken(params[0].access_token);
          setAccessToken(params[0].access_token);

          const me = await fetchMe();

          config.appEnv === 'production' && sendTwitterCompeteteRegistrationEvent(me);

          mixpanel?.alias(`${me.id}`);
          mixpanel?.track('Register');
          mixpanel?.track('New User');
          cache.setMe(me);

          options?.onSuccess?.(...params);
        } catch (e) {
          console.error(e);
        }
      },
    },
  );
}
