import {ChangeEvent, FormEventHandler, useEffect, useState} from 'react';
import NextLink from 'next/link';
import {useRouter} from 'next/router';

import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Link,
  Text,
  useToast,
  Box,
  Select,
  Flex,
} from '@chakra-ui/react';

import {useTranslate} from '@eksab/i18n';
import {useAccessToken} from '@eksab/hooks';
import {Bold, Br} from '@eksab/i18n/components';
import {EmailIcon, LockIcon, ViewIcon} from '@eksab/assets/icons';

import {useLoginMutation} from '../hooks';
import {getFlagEmoji} from '../utils';
import {forceEnglishDigits, getCountry, getLanguage} from '@eksab/util';
import {Tabs} from '@eksab/components/tabs';
import {regionsIsoCode, regionsPhonePrefix} from '@eksab/const';

type Params = Parameters<ReturnType<typeof useLoginMutation>['mutate']>[0];

type LoginProps =
  | {
      isModal: true;
      closeModal: () => void;
      onSignUpClick?: () => void;
    }
  | {
      isModal?: false;
    };

export function Login(props: LoginProps) {
  const toast = useToast();
  const router = useRouter();

  const t = useTranslate();
  const {data: accessToken} = useAccessToken();
  const loginMutation = useLoginMutation({
    onSuccess: (res) => {
      toast({title: t('auth.login_successful'), status: 'success'});
      if (props.isModal) return props.closeModal();

      return router.replace(decodedFrom, undefined, {
        locale: `${getLanguage(router.locale)}-${res.extra.additional.region}`,
      });
    },
    onError: (e) => void toast({title: e.response?.data?.message ?? t('auth.login_failed'), status: 'error'}),
  });

  const decodedFrom = decodeURIComponent((router.query.from as string | undefined) ?? '/');

  useEffect(() => {
    if ('email-verified' in router.query) toast({title: t('auth.email_verified'), status: 'success'});
  }, [router.query, t, toast]);

  useEffect(() => {
    if (accessToken && !props.isModal) router.replace(decodedFrom, undefined, {locale: router.locale});
  }, [accessToken, props.isModal, router, decodedFrom]);

  const onSubmit: (params: Params) => FormEventHandler<HTMLFormElement> = (params) => (e) => {
    e.preventDefault();

    loginMutation.mutate(params);
  };

  return (
    <>
      <Spacer />
      <Text fontWeight="black" fontSize="2xl" mb="1">
        {t('auth.welcome')}
      </Text>

      <Text fontSize="sm" mb="10">
        {t('auth.brag', {b: Bold, br: Br})}
      </Text>

      <LoginForm onSubmit={onSubmit} isLoading={loginMutation.isLoading} />
      <Spacer />

      <Box mb="2">
        <NextLink href="/reset-password" passHref legacyBehavior>
          <Link fontSize="xs" fontWeight="700" color="gray.500" textDecor="underline" _hover={{color: 'black'}}>
            {t('auth.forgot_password')}
          </Link>
        </NextLink>
      </Box>

      {props.isModal && props.onSignUpClick && (
        <NextLink
          href={props.isModal ? {href: null, query: router.query} : {pathname: '/register', query: router.query}}
          scroll={!props.isModal}
          passHref
          legacyBehavior
        >
          <Link
            as="button"
            onClick={props.onSignUpClick}
            fontSize="xs"
            fontWeight="700"
            color="gray.500"
            _hover={{color: 'black'}}
          >
            {t('auth.no_account') + ' '}
            <Text as="span" color="unset" textTransform="capitalize" textDecor="underline">
              {t('app.actions.register')}
            </Text>
          </Link>
        </NextLink>
      )}
    </>
  );
}

type FormParams = {
  onSubmit: (params: Params) => React.FormEventHandler<HTMLFormElement>;
  isLoading: boolean;
};

const loginMethods = ['phone', 'email'] as const;

type LoginMethod = (typeof loginMethods)[number];

function LoginForm(props: FormParams) {
  const locale = useRouter().locale;
  const [loginMethod, setLoginMethod] = useState<LoginMethod>('phone');

  const [values, setValues] = useState<Params>({identifier: '', password: '', region: getCountry(locale)});
  const [passwordVisible, setPasswordVisible] = useState(false);
  useEffect(() => {
    setValues((values) => ({...values, identifier: ''}));
  }, [loginMethod]);

  const t = useTranslate();

  const isPhoneLogin = loginMethod === 'phone';

  const togglePasswordVisibility = () => setPasswordVisible((current) => !current);

  const onChange = (key: keyof Params) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value = e.target.value;

    if (key === 'identifier' && isPhoneLogin) {
      value = forceEnglishDigits(value);
    }

    setValues((old) => ({...old, [key]: value}));
  };

  return (
    <>
      <Tabs border="none" bgColor="#eaeaea" p="2.5" gap="1.5" mx="auto" mb="3.5">
        {loginMethods.map((method) => (
          <Tabs.Item
            key={method}
            title={`auth.login-method.${method}`}
            isActive={loginMethod === method}
            fontWeight="600"
            fontSize="sm"
            _active={{bgColor: 'green.400'}}
            py="1.5"
            px="2.5"
            onClick={() => setLoginMethod(method)}
          />
        ))}
      </Tabs>

      <form
        onSubmit={props.onSubmit(
          isPhoneLogin
            ? {...values, identifier: regionsPhonePrefix[values.region] + values.identifier}
            : {...values, region: getCountry(locale)},
        )}
      >
        <Flex mb="2.5" gap="2.5" flexDirection={getLanguage(locale) === 'ar' ? 'row-reverse' : 'row'}>
          {isPhoneLogin && (
            <Select borderRadius="6px" onChange={onChange('region')} value={values.region} w="30">
              {regionsIsoCode.map((regionIsoCode) => (
                <option key={regionIsoCode} value={regionIsoCode}>
                  {getFlagEmoji(regionIsoCode)}
                </option>
              ))}
            </Select>
          )}
          <FormControl isRequired flex="1">
            <InputGroup dir={isPhoneLogin ? 'ltr' : undefined}>
              {isPhoneLogin ? (
                getLanguage(locale) === 'ar' ? (
                  <InputRightElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                    {regionsPhonePrefix[values.region]}
                  </InputRightElement>
                ) : (
                  <InputLeftElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                    {regionsPhonePrefix[values.region]}
                  </InputLeftElement>
                )
              ) : (
                <InputLeftElement pointerEvents="none" fontWeight="600" alignItems="center">
                  <EmailIcon />
                </InputLeftElement>
              )}

              <Input
                paddingStart={isPhoneLogin ? '14' : '10'}
                paddingEnd={0}
                id="identifier"
                type={isPhoneLogin ? 'tel' : 'email'}
                inputMode={isPhoneLogin ? 'tel' : 'email'}
                autoComplete={isPhoneLogin ? 'tel' : 'email'}
                placeholder={t(isPhoneLogin ? 'auth.number' : 'auth.email')}
                value={values.identifier}
                onChange={onChange('identifier')}
              />
            </InputGroup>
          </FormControl>
        </Flex>
        <FormControl isRequired>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <LockIcon />
            </InputLeftElement>

            <Input
              id="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder={t('auth.password')}
              value={values.password}
              onChange={onChange('password')}
            />

            <InputRightElement>
              <ViewIcon opacity={passwordVisible ? 1 : 0.4} onClick={togglePasswordVisibility} />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Button
          w="full"
          mt="5"
          mb="3"
          type="submit"
          disabled={!values.identifier || !values.password || props.isLoading}
          isLoading={props.isLoading}
        >
          {t('app.actions.login')}
        </Button>
      </form>
    </>
  );
}
