import React, {useState} from 'react';
import Script from 'next/script';
import {useRouter} from 'next/router';
import NextLink from 'next/link';
import {useIntl} from 'react-intl';
import {Text, Link as ChakraLink, Flex, Center} from '@chakra-ui/layout';
import {Input, InputGroup, InputLeftElement, InputRightElement} from '@chakra-ui/input';
import {FormControl} from '@chakra-ui/form-control';
import {Button} from '@chakra-ui/button';
import {useToast} from '@chakra-ui/toast';

import {useTranslate} from '@eksab/i18n';
import {Bold, Br} from '@eksab/i18n/components';
import {Image} from '@eksab/components/Image';
import {useAppSettingsQuery} from '@eksab/hooks/useAppSettingsQuery';
import {EmailIcon} from '@eksab/assets/icons/EmailIcon';
import {InfoCardIcon} from '@eksab/assets/icons/InfoCardIcon';
import {LockIcon} from '@eksab/assets/icons/LockIcon';
import {ViewIcon} from '@eksab/assets/icons/ViewIcon';
import {config} from '@eksab/config';
import {regionsPhonePrefix} from '@eksab/const';
import {forceEnglishDigits, getCountry, getLanguage} from '@eksab/util';

import {AccountCreated} from './AccountCreated';
import {VerifyNumber} from './VerifyNumber';
import {RegistrationReward} from './RegistrationReward';
import {usePartnerReferralStore, useSetPartnerReferral} from '../hooks/usePartnerReferralStore';
import {getFlagEmoji, validateRegisterForm} from '../utils';
import type {RegistrationResponse} from '../types';

type RegisterProps =
  | {
      isModal: true;
      closeModal: () => void;
      onLoginClick?: () => void;
    }
  | {
      isModal?: false;
    };

export type RegisterParams = {
  name: string;
  email?: string;
  phone_number: string;
  password: string;
  recaptcha: string;
  referrer?: string;
};

export function Register(props: RegisterProps) {
  const router = useRouter();
  const format = useIntl().formatNumber;
  const [values, setValues] = useState<RegisterParams>({
    name: '',
    email: '',
    password: '',
    phone_number: '',
    recaptcha: '',
  });
  const [verifyNumber, setVerifyNumber] = useState(false);
  const [registrationResponse, setRegistrationResponse] = useState<RegistrationResponse | null>(null);
  const toast = useToast();

  useSetPartnerReferral();
  const t = useTranslate();
  const {data: settings} = useAppSettingsQuery();
  const partner = usePartnerReferralStore((state) => state.partner);
  const resetPartnerStore = usePartnerReferralStore((state) => state.reset);

  const registrationReward = registrationResponse?.extra.additional.applied_partner_codes?.[0]?.actions.find(
    (action) => action.action === 'reward-coins',
  );

  const onVerificationSuccess = (data: RegistrationResponse) => {
    setRegistrationResponse(data);
    const partnerReferral = data.extra.additional.applied_partner_codes?.[0];
    const registrationReward = partnerReferral?.actions.find((action) => action.action === 'reward-coins');

    if (partnerReferral) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete(`${partnerReferral.partner}-code`);
      router.replace({query: searchParams.toString()}, undefined, {shallow: true});
      resetPartnerStore();
    }

    if (props.isModal) {
      props.closeModal();

      if (!registrationReward) return;

      return toast({
        title: t('auth.registration.reward.coins', {
          coins: format(registrationReward.coins),
          br: () => <br />,
        }),
        status: 'success',
      });
    }

    const showBuyCoinsStep = settings?.buy_coins_after_sign_up;

    if (!registrationReward && showBuyCoinsStep) return;

    const from = (router.query.from ?? '/') as string;
    setTimeout(() => {
      router.replace(decodeURIComponent(from));
    }, 3000);
  };

  const goBack = () => setVerifyNumber(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mixpanel?.track('Register.Confirm');

    window.grecaptcha?.ready(() =>
      window.grecaptcha!.execute(config.recaptchaSiteKey!, {action: 'submit'}).then((token) => {
        const valuesWithRecaptcha = {
          ...values,
          recaptcha: token,
        };
        setValues(valuesWithRecaptcha);

        const error = validateRegisterForm(
          t,
          valuesWithRecaptcha,
          settings?.phone_number_regex || '',
          regionsPhonePrefix[getCountry(router.locale)],
        );
        if (error) return toast({title: error.message, status: 'error'});

        setVerifyNumber(true);
      }),
    );
  };

  if (!!registrationReward) {
    return <RegistrationReward reward={registrationReward} />;
  }

  if (!!registrationResponse) {
    return <AccountCreated />;
  }

  if (verifyNumber) {
    return <VerifyNumber values={values} onVerificationSuccess={onVerificationSuccess} goBack={goBack} />;
  }

  return (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaSiteKey}`} strategy="lazyOnload" />

      {partner ? (
        <Flex as="header" flexDir="column" align="center" gap="4" textAlign="center" mb="4">
          <Text as="h1" fontWeight="800" fontSize="2xl" mb="1">
            {t(partner.brand)}
          </Text>

          <Text fontSize="md" fontWeight="300">
            {t('auth.brought-by')}
          </Text>

          <Flex align="center" gap="4">
            <Image
              title={t(partner.name)}
              src={partner.logo}
              alt={t(partner.name)}
              width="96"
              height="96"
              w="24"
              h="auto"
            />

            <Image src="/images/partners/x.png" alt="" width="32" height="50" w="8" h="auto" />

            <Flex title={t('app.name')} bg="black" boxSize="24" px="3" py="7">
              <Image src="/logos/eksab-split.svg" alt={t('app.name')} width="80" height="40" w="full" h="auto" />
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Text as="h1" fontWeight="black" fontSize="2xl" mb="1" data-testid="join_to_win">
          {t('auth.join_to_win')}
        </Text>
      )}

      <Text fontSize="sm" mb="10" opacity="0.6">
        {t('auth.brag', {b: Bold, br: Br})}
      </Text>

      <RegisterForm values={values} setValues={setValues} onSubmit={onSubmit} />

      {props.isModal && props.onLoginClick && (
        <NextLink
          href={props.isModal ? {href: null, query: router.query} : {pathname: '/login', query: router.query}}
          scroll={!props.isModal}
          passHref
          legacyBehavior
        >
          <ChakraLink
            as="button"
            mt="auto"
            onClick={props.onLoginClick}
            fontSize="xs"
            fontWeight="700"
            color="gray.500"
            _hover={{color: 'black'}}
          >
            {t('auth.have_an_account')}{' '}
            <Text as="span" textDecor="underline" color="unset">
              {t('app.actions.login')}
            </Text>
          </ChakraLink>
        </NextLink>
      )}
    </>
  );
}

type FormParams = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  values: RegisterParams;
  setValues: React.Dispatch<React.SetStateAction<RegisterParams>>;
};

function RegisterForm({onSubmit, values, setValues}: FormParams) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const locale = useRouter().locale;

  const t = useTranslate();

  const togglePasswordVisibility = () => setPasswordVisible((current) => !current);

  const onChange = (key: keyof RegisterParams) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (key === 'phone_number') {
      value = forceEnglishDigits(value);
    }

    setValues((old) => ({...old, [key]: value}));
  };

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired mb="2.5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <InfoCardIcon width="5" height="4" />
          </InputLeftElement>

          <Input
            id="name"
            placeholder={t('auth.name')}
            value={values.name}
            onChange={onChange('name')}
            autoComplete="name"
            isRequired
          />
        </InputGroup>
      </FormControl>

      <Flex gap="2.5" flexDirection={getLanguage(locale) === 'ar' ? 'row-reverse' : 'row'}>
        <Center borderWidth={1} w="10" h="10" rounded="sm">
          {getFlagEmoji(getCountry(locale))}
        </Center>
        <FormControl isRequired mb="2.5">
          <InputGroup dir="ltr">
            {getLanguage(locale) === 'en' ? (
              <InputLeftElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                {regionsPhonePrefix[getCountry(locale)]}
              </InputLeftElement>
            ) : (
              <InputRightElement pointerEvents="none" fontWeight="600" w={14} alignItems="center">
                {regionsPhonePrefix[getCountry(locale)]}
              </InputRightElement>
            )}
            <Input
              paddingStart={14}
              paddingEnd={0}
              id="phone"
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              placeholder={t('auth.number')}
              value={values.phone_number}
              onChange={onChange('phone_number')}
            />
          </InputGroup>
        </FormControl>
      </Flex>

      <FormControl mb="2.5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <EmailIcon width="4" height="4" />
          </InputLeftElement>

          <Input
            id="email"
            type="email"
            autoComplete="email"
            placeholder={`${t('auth.email')} ${t('app.field.optional')}`}
            value={values.email}
            onChange={onChange('email')}
            ps="16"
          />
        </InputGroup>
      </FormControl>

      <FormControl isRequired mb="5">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <LockIcon />
          </InputLeftElement>

          <Input
            id="password"
            type={passwordVisible ? 'text' : 'password'}
            autoComplete="new-password"
            placeholder={t('auth.password')}
            value={values.password}
            onChange={onChange('password')}
          />

          <InputRightElement>
            <ViewIcon opacity={passwordVisible ? 1 : 0.4} onClick={togglePasswordVisibility} />
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <Text fontSize="10" color="gray.500" textAlign="start" mb="2.5">
        {t('auth.recaptcha', {
          a1: (privacy) => (
            <ChakraLink
              textDecor="underline"
              _hover={{color: 'black'}}
              color="unset"
              href="https://policies.google.com/privacy?hl=en-GB"
              fontSize="10"
            >
              {privacy}
            </ChakraLink>
          ),
          a2: (terms) => (
            <ChakraLink
              textDecor="underline"
              _hover={{color: 'black'}}
              color="unset"
              href="https://policies.google.com/terms?hl=en-GB"
              fontSize="10"
            >
              {terms}
            </ChakraLink>
          ),
        })}
      </Text>

      <Button w="100%" mb="7" type="submit">
        {t('app.actions.register')}
      </Button>
    </form>
  );
}
